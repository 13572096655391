import styles from './index.module.scss';

export const Icon = () => (
  <div className={styles.iconWrapper}>
    <svg width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <path
          id="Vector"
          d="M1.56134 0L0.438965 1.12222L3.31668 3.99994L0.438965 6.87778L1.56134 8L5.56134 3.99994L1.56134 0Z"
          fill="white"
        />
      </g>
    </svg>
  </div>
);
