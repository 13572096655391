'use client';

import { useState } from 'react';
import cx from 'classnames';
import { MenuContent } from './MenuContent';
import styles from './index.module.scss';

export const SpMenu = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={styles.spMenuContainer}>
      <div
        className={styles.navButton}
        role="button"
        aria-controls={styles.navContent}
        onClick={() => setIsOpen((prev) => !prev)}
      >
        <span className={styles.hamburgerIcon}>
          <span className={cx(styles.borderCenter, isOpen && styles['-open'])}></span>
        </span>
      </div>
      <MenuContent isOpen={isOpen} close={() => setIsOpen(false)} />
    </div>
  );
};
