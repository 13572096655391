import { omit } from '@/app/_utils';

export type BlogCategory = 'キャリア' | 'ITツール' | 'プログラミング' | 'テックブログ';

export type BlogPostCategory =
  | 'none'
  | 'all'
  | 'popularity'
  | 'programming'
  | 'carrier'
  | 'tools'
  | 'techBlog';

export const BLOG_POST_CATEGORY: MappedConst<BlogPostCategory> = {
  none: 'none',
  all: 'all',
  popularity: 'popularity',
  programming: 'programming',
  carrier: 'carrier',
  tools: 'tools',
  techBlog: 'techBlog',
};

export const isBlogPostCategory = (
  category: string,
): category is Exclude<BlogPostCategory, 'none'> =>
  Object.keys(omit(BLOG_POST_CATEGORY, 'none')).some((c) => c === category);

export const categoryUrlParamGenerator = (
  categoryString: BlogCategory,
): Exclude<BlogPostCategory, 'none' | 'popularity' | 'all'> => {
  switch (categoryString) {
    case 'ITツール': {
      return BLOG_POST_CATEGORY.tools;
    }
    case 'キャリア': {
      return BLOG_POST_CATEGORY.carrier;
    }
    case 'テックブログ': {
      return BLOG_POST_CATEGORY.techBlog;
    }
    case 'プログラミング': {
      return BLOG_POST_CATEGORY.programming;
    }
  }
};
