'use client';

import Link from 'next/link';
import Image from 'next/image';
import { Icon } from './Icon';
import { BLOG_POST_CATEGORY } from '@/app/_models/blog/category';
import styles from './index.module.scss';

type Props = {
  isOpen: boolean;
  close: () => void;
};

const HEADER_HEIGHT = 57;
const MENU_CONTENTS = [
  {
    href: `/blog/${BLOG_POST_CATEGORY.all}`,
    label: '全ての記事',
  },
  {
    href: `/blog/${BLOG_POST_CATEGORY.popularity}`,
    label: '人気の記事',
  },
  {
    href: `/blog/${BLOG_POST_CATEGORY.programming}`,
    label: 'プログラミング学習',
  },
  {
    href: `/blog/${BLOG_POST_CATEGORY.carrier}`,
    label: 'キャリア',
  },
  {
    href: `/blog/${BLOG_POST_CATEGORY.tools}`,
    label: 'ITツール活用',
  },
  {
    href: `/blog/${BLOG_POST_CATEGORY.techBlog}`,
    label: 'テックブログ',
  },
] as const;

export const MenuContent = ({ isOpen, close }: Props) => (
  <nav
    className={styles.navContent}
    aria-hidden={!isOpen}
    style={{
      height: isOpen ? `${window.innerHeight - HEADER_HEIGHT}px` : 0,
      visibility: isOpen ? 'visible' : 'hidden',
      opacity: isOpen ? 1 : 0,
    }}
  >
    <ul className={styles.linkList}>
      {MENU_CONTENTS.map(({ href, label }, i) => {
        return (
          <li key={`header-menu-item-${i}`} className={styles.linkWrapper}>
            <Link href={href} className={styles.link} onClick={close}>
              <Icon />
              <span className={styles.text}>{label}</span>
            </Link>
          </li>
        );
      })}
    </ul>

    <ul className={styles.otherLintList}>
      <li className={styles.linkWrapper}>
        <Link href="/recruit" className={styles.link} onClick={close}>
          採用情報
        </Link>
      </li>
      {/* <li className={styles.linkWrapper}>
        <a href="/" className={styles.link}>
          法人の方はこちら
        </a>
      </li> */}
      <li className={styles.linkWrapper}>
        <Link href="/" onClick={close}>
          <Image className={styles.Icon} src="/full-stack-icon.png" alt="" width="20" height="20" />
          フルスタック株式会社
        </Link>
      </li>
    </ul>
  </nav>
);
